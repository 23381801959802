import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout/index.jsx";
import Body from '../../components/Body';
import Headline from '../../components/Body/Headline';
import Donate from '../../components/Donate';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Body withoutHero mdxType="Body">
  <Headline level="1" standalone mdxType="Headline">FAQ / Dictionary</Headline>
  <Headline level="2" as="3" standalone mdxType="Headline">What is a Search and Rescue Zone (SAR-Zone)?</Headline>
      <p>{`  A SAR-Zone is a defined area of responsibility combined with a `}<strong parentName="p">{`Rescue Coordination Center (RCC)`}</strong>{` run by the responsible coastal state. Such a center must be able to perform search and rescue operations at sea and coordination of such. According to the international SAR Convention (Annex Chapter 2, Paragraph 2.2.3.) an RCC needs to always provide an English-speaking staff member, professional staff, and equipment to perform and coordinate search and rescue operations in that area. RCCs and therefore the combined SAR-Zone are designated by the International Maritime Organization (IMO).`}</p>
  <br /><br />
  <Headline level="2" as="3" standalone mdxType="Headline">What is a Push-back?</Headline>
      <p>{`  The term push-back describes a political practice where people are either obstructed from crossing a border, or forced back by the authority of the destination country, without being given the opportunity to apply for asylum. The state in which a person wants to apply for asylum must provide an individual review of each case, access to legal assistance and translators. In the event that asylum is denied, the state must provide time for an appeal. Push-backs are often used against whole groups of people on the move, which directly violates the prohibition of collective expulsion, as stated not only in the European Convention on Human Rights, but many other International Treaties*.`}</p>
      <p>{`  *Art. 33 of the 1951 Refugee Convention and Art. 3 of the United Nations Convention against Torture and Other Cruel, Inhuman or Degrading Treatment or Punishment.`}</p>
  <br /><br />
  <Headline level="2" as="3" standalone mdxType="Headline">What is a pull-back?</Headline>
      <p>{`  Pull-backs refer to state measures whereby people on the move are obstructed or intercepted during an attempt to cross a border by the authorities of the state they’re leaving, without being given the opportunity to apply for asylum. In the central Mediterranean, we mostly witness pull-backs executed by armed Libyan groups, often violently, and with the support of `}<AnchorLink to="/faq#who-is-frontex" mdxType="AnchorLink">{`Frontex`}</AnchorLink>{` or other EU actors, therefore in violation of the above mentioned conventions.`}</p>
  <br /><br />
  <Headline level="2" as="3" standalone mdxType="Headline">What is the Principle of Non-Refoulement?</Headline>
      <p>{`  The terms push-back or pull-back are referring to the Principle of Non-Refoulement, as stated in the European Convention for Human Rights in Art. 3.: “No one shall be subjected to torture or to inhuman or degrading treatment or punishment.”* These conventions forbid actions by a state that violate the basic human rights of any person. Refusing to give an individual the chance to apply for asylum, and therefore to receive proper juridical support, is a direct violation of their fundamental right, as decided by the European Court on Human Rights in 2012, Jamal Hirsi and Others Vs. Italy, read more about the case `}<a parentName="p" {...{
          "href": "https://www.asylumlawdatabase.eu/en/content/ecthr-hirsi-jamaa-and-others-v-italy-gc-application-no-2776509"
        }}>{`here`}</a>{`. `}</p>
      <p>{`  *Also stated in Art. 33 of the 1951 Refugee Convention and Art. 3 of the United Nations Convention Against Torture and Other Cruel, Inhuman or Degrading Treatment or Punishment.`}</p>
  <br /><br />
  <Headline level="2" as="3" standalone mdxType="Headline">Who is Frontex?</Headline>
      <p>{`  Frontex (Frontières Extérieures - external borders), the European Border and Coast Guard Agency, is an agency implemented by the European Union to monitor and coordinate operations to prevent irregular migration on the EU’s external border. Frontex operates high tech equipment such as drones and reconnaissance aircrafts. The agency's budget has been growing since its founding: from 19 million Euro in 2006, to `}<a parentName="p" {...{
          "href": "https://ec.europa.eu/info/sites/default/files/about_the_european_commission/eu_budget/mff_factsheet_agreement_en_web_20.11.pdf"
        }}>{`5.6 billion Euro`}</a>{` for the period from 2021 to 2027. Frontex has been repeatedly criticised for its involvement in illegal interceptions, and the lack of an independent body that controls its actions. Frontex operations in the central Mediterranean are increasingly focused on monitoring and supporting illegal `}<AnchorLink to="/faq#what-is-a-pull-back" mdxType="AnchorLink">{`pull-backs`}</AnchorLink>{`. Without a single operational ship, Frontex searches for boats by using drones, aircraft, and other observation equipment. It has been repeatedly reported that Frontex shares the information they gain with the `}<AnchorLink to="/faq#what-is-the-so-called-libyan-coast-guard" mdxType="AnchorLink">{`so-called Libyan Coast Guard`}</AnchorLink>{` who, in turn, are proven to execute illegal pull-backs with this information.`}</p>
      <p>{`  Reports on the actions of Frontex can be found `}<a parentName="p" {...{
          "href": "https://frontexfiles.eu/en.html"
        }}>{`here`}</a>{` and `}<a parentName="p" {...{
          "href": "https://www.infomigrants.net/en/post/38907/frontex-leaders-concealed-pushbacks-watchdog-reports"
        }}>{`here`}</a>{`.`}</p>
  <br /><br />
  <Headline level="2" as="3" standalone mdxType="Headline">Who as we referring to as “people on the move”, “guests” or “survivors”?</Headline>
      <p>{`  We avoid certain designations, such as “migrants” or “refugees”, because what defines a person is far more than their legal status in relation to certain laws. By always referring to `}<strong parentName="p">{`“people on the move”`}</strong>{` we want to highlight that being on the move is a non-permanent action, taken to find a better place to live for themselves, which we strongly believe is everybody’s right. We therefore refer to rescued people as `}<strong parentName="p">{`“guests”`}</strong>{` and `}<strong parentName="p">{`“survivors”`}</strong>{` because we do not want to define them beyond the position that they are in at that moment, which is as guests aboard Louise Michel. We do not define ourselves as rescuers, but as activists who actively support all people on the move!`}</p>
  <br /><br />
  <Headline level="2" as="3" standalone mdxType="Headline">What is a “port of safety” or “place of safety”?</Headline>
      <p>{`  The International Maritime Organization (IMO) defines a Place of Safety as one where, “the survivor’s” safety of life is no longer threatened and where their basic human needs (such as food, shelter and medical) can be met” *. Important to mention in this context, is the decision by the European Court of Human Rights (ECHR), in the case `}<a parentName="p" {...{
          "href": "https://hudoc.echr.coe.int/eng#%7B%22itemid%22:%5B%22001-109231%22%5D%7D"
        }}>{`Hirsi and Jamaa v. Italy`}</a>{`, 2012. During this case, the court stated that Libya cannot be considered a safe place and pointed to the fact that a decision as to whether an individual has a right to legal status of residency in the EU cannot be taken on a ship, because proper legal assistance, translation, and the possibility to fight legal decisions, must be provided.`}</p>
      <p>{`  A `}<strong parentName="p">{`port of safety`}</strong>{` is not a legal term but refers to the assigned port that a ship can sail into after a rescue has been carried out. It follows that a Place of Safety is often called the `}<strong parentName="p">{`port of safety`}</strong>{`, since a place of safety on sea, is always a port. `}</p>
      <p>{`  *IMO Resolution MSC.167(78), 6.12.`}</p>
  <br /><br />
  <Headline level="2" as="3" standalone mdxType="Headline">What is the “so-called Libyan Coast Guard”?</Headline>
      <p>{`  Since 2016, the EU has been financing, training and equipping armed forces that prevent people from leaving Libya over the sea border (known as a `}<AnchorLink to="/faq#what-is-a-pull-back" mdxType="AnchorLink">{`pull-back`}</AnchorLink>{`). By outsourcing direct measures against migration to groups in Libya the EU isn’t technically committing a breach of international law itself, although it is complicit, because bringing people rescued in the Mediterranean Sea to Libya is considered an illegal `}<AnchorLink to="/faq#what-is-a-push-back" mdxType="AnchorLink">{`push-back`}</AnchorLink>{` by the European Court of Human Rights (Hirsi Jamaa v. Italy). The EU calls these Libyan forces “Coast Guard”, which is a strategy to build up a legitimacy for their own anti-migration policy. `}</p>
      <p>{`  We, however, use the term “so-called Libyan Coast Guard” to highlight the bypassing of human rights by the EU. The so-called Libyan Coast Guard does not have a working Rescue Coordination Center (RCC) according to international standards, nor are they able to carry out rescues in compliance with international law. Since the “so-called Libyan Coast Guard” was established in 2018, the EU avoids its responsibility for human rights violations by referring to this “officially” responsible actor. We will continue to point out the responsible actors: the EU and its member states.`}</p>
  <br /><br />
  <Headline level="2" as="3" standalone mdxType="Headline">What is "Fortress Europe"?</Headline>
      <p>{`  The term ‘Fortress Europe’ is mostly used to critically describe the EU’s aggressive and defensive policy against migration from “third countries”, which is how the EU classifies any country that is a non-EU member state. We use the term “Fortress Europe” to symbolise that the EU’s migration policy is forcefully and violently trying to control and prevent migration to Europe. The EU’s external borders are, at great expense, militarised by both national military forces and through EU agencies such as Frontex. Frontex uses drones and weapons against people on the move, while spinning the racist narrative that the European external borders must be “secured”. People on the move who seek their right to asylum have reportedly been exposed to push- or pull-backs, and violation of their basic human rights, a consequence of the border militarisation.`}</p>
  <br /><br />
  <Headline level="2" as="3" standalone mdxType="Headline">Why Tunisia is not a safe place?</Headline>
      <p>{`  Tunisia is not providing a national asylum system. This therefore disqualifies Tunisia as a place where everyone’s basic human rights are covered, as the right to seek asylum is a human right. A rescue at sea does not end with the evacuation onto a safe vessel, it is only completed when all rescued people can safely disembark in a safe place. No place where basic human rights are threatened can ever be considered safe. Furthermore, people who are defining themselves, or are being defined by others as LGBTQIN*, are especially in danger from authorities in Tunisia, as several reports by human rights organisations show (`}<a parentName="p" {...{
          "href": "https://www.hrw.org/sitesearch?search=&sort_by=created&f%5B0%5D=region_country%3A9649&f%5B1%5D=topic%3A9691"
        }}>{`Source`}</a>{`). Since it is the right of each person, regardless of nationality, religion, sexual orientation or gender, to be brought to a safe place in order to apply for asylum, Tunisia cannot be considered a `}<AnchorLink to="/faq#what-is-a-port-of-safety-or-place-of-safety" mdxType="AnchorLink">{`Place of Safety`}</AnchorLink>{`.`}</p>
      <p>{`  Further reports on why Tunisia is not safe can be found `}<a parentName="p" {...{
          "href": "https://link.springer.com/content/pdf/10.1007%2Fs12134-018-0596-7.pdf"
        }}>{`here`}</a>{` and `}<a parentName="p" {...{
          "href": "https://www.amnesty.org/en/search/Lgbt%20tunisia/?language=en"
        }}>{`here`}</a>{`.`}</p>
  <br /><br />
  
  <Headline level="2" as="3" standalone mdxType="Headline">What is white saviourism?</Headline>
      <p>{`  White saviourism describes an ideology whereby a white person or a group of white people feel called upon to “save” BIPOC persons, or nations in the global south, through development, educational or aid work. According to the organisation `}<a parentName="p" {...{
          "href": "https://twitter.com/nowhitesaviors?s=20&t=kbTiHHG-8pvtmZEV2Trw7Q"
        }}>{`@nowhitesaviors`}</a>{` it can be described as a “symptom of racism & white supremacy which places those in a position of privilege into the role of savior over those who have been historically oppressed and exploited”. Initially, the author Teju Cole introduced the term `}<a parentName="p" {...{
          "href": "https://www.theatlantic.com/international/archive/2012/03/the-white-savior-industrial-complex/254843/"
        }}>{`White-Savior Industrial Complex`}</a>{` in 2012. He describes that white saviourism “is not about justice. It is about having a big emotional experience that validates privilege”. `}</p>
      <p>{`  We are aware that in sea rescue, white saviorism can become a problem, as it’s mainly white privileged people and organisations that are in the position of “rescuers”. It is our firm belief that it takes far more courage and strength for people on the move to undertake the dangerous journey towards Europe, against all the measures taken by European states, than for privileged activists who perform rescues. For us, this has nothing to do with being a hero. We want to show solidarity with people who are negatively affected by the system that allows us the privileges that do not extend to BIPOC persons.`}</p>
      <p>{`  “We never said ‘No White People’ – We just know you shouldn’t be the hero of the story.” (No White Saviors, 2020)`}</p>
    </Body>
    <Donate text="Donate" isVisible mdxType="Donate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      